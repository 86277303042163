 <lib-table 
[tableColumns]="resellerColumns" 
[api]="api" 
[title]="title" 
[refreshButton]="true"
[exportButton]="exportButton"
[addNewButton]="addNewButton"
[pdfButton]="false"
[searchUIOptions]="searchUIOptions"
[resourceMessages]="resourceMessages"
[dateFilter]="dateFilter"
[dropDownFilter]="dropDownFilter"
[singleDateFilterDivClass]="'col-md-4'"
[searchInputDivClass]="'col-md-4'"
[selectInputDivClass]="'col-md-4'"
(smHandleUpdateButtonClick)="handleUpdateButtonClick($event)"
>
</lib-table>