 <lib-table 
[tableColumns]="resellerColumns" 
[api]="api" 
[title]="title" 
[refreshButton]="refreshButton"
[exportButton]="exportButton"
[addNewButton]="addNewButton"
[pdfButton]="pdfButton"
[searchUIOptions]="searchUIOptions"
[resourceMessages]="resourceMessages"
(smHandleUpdateButtonClick)="handleUpdateButtonClick($event)"
>
</lib-table>