<lib-title-bar [title]="title" [refreshButton]="false" [requestReportButton]="true"
    (handleRequestGLButtonClick)="request()" [addNewButton]="false"></lib-title-bar>

<form novalidate #frm="ngForm">
    <lib-form-panel>
        <div class="row">
            <div class="col-md-5">
                <lib-date-picker [smLabelClass]="'col-md-3 text-start'" [smName]="'from'" [(smModel)]="fromDate"
                    [smDisabled]="false" [smLabel]="'From'" />
            </div>
            <div class="col-md-5">
                <lib-date-picker [smLabelClass]="'col-md-3 text-start'" [smName]="'to'" [(smModel)]="toDate"
                    [smDisabled]="false" [smLabel]="'To'" />
            </div>
            <div class="col-md-2">
            </div>
        </div>

    </lib-form-panel>
</form>

<lib-title-bar [title]="'Report Requests Today'" [exportButton]="false" (handleRefreshButtonClick)="refreshRequests()"
    [pdfButton]="false" [addNewButton]="false" />
<p *ngIf="!requests || requests.length === 0">No Requests made today.</p>
<table class="table table-hover table-condensed table-bordered" *ngIf="requests.length > 0">
    <thead>
        <tr>
            <th class="col-md-2">When</th>
            <th class="col-md-2">Status</th>
            <th class="col-md-1">Download</th>
            <th class="col-md-7">Info</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let o of requests">
            <td>{{o.requestedSAST|date:'hh:mm:ss'}}</td>
            <td>{{o.status}}</td>
            <td nowrap class="text-center">
                <lib-button *ngIf="o.downloadLink" [smIcon]="'fa fa-cloud-download'"
                    (smClick)="downloadReport(o.downloadLink)" [smClass]="'btn btn-primary btn-sm'"
                    [smTitle]="'Download link for All ABSA Companies Report'" />
            </td>
            <td></td>
        </tr>
    </tbody>
</table>