<lib-table
  [tableColumns]="subscriptionColumns"
  [api]="api"
  [title]="title"
  [refreshButton]="true"
  [exportButton]="false"
  [addNewButton]="false"
  [pdfButton]="false"
  [searchUIOptions]="searchUIOptions"
  [downloadButton]="true"
  [resourceMessages]="resourceMessages"
  [dropDownFilter]="dropDownFilter"
  (smHandleDownloadButtonClick)="downloadSubscriptionsEndingSoon()"
  (smHandleUpdateButtonClick)="handleUpdateButtonClick($event)"
  [searchInputDivClass]="'col-md-4'"
  [selectInputDivClass]="'col-md-4'"
  (smHandleDropdownClick)="daySelect($event)"
>
</lib-table>
