import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { portalConstants } from "packages/shared-lib/src/lib/constants/portal.constants";
import { Column, ColumnType, api, searchUIOptions, resourceMessages } from "packages/shared-lib/src/lib/interfaces/webclient.interface";
import { webPortal } from "packages/shared-lib/src/lib/services/api/webportal.api";

@Component({
  selector: "admin-handovers",
  templateUrl: "./handovers.component.html",
})
export class HandoversComponent {

  constructor(private router: Router){}

  updateURL = '/companies/company-'

  title = "Absa Registrations Being Handed Over"
  handoverColumns: Column[] = [
    { columnDef: 'sanitizedName', header: 'Company Login', columnType: ColumnType.link },
    { columnDef: 'primaryUserName', header: 'Username' },
    { columnDef: 'registeredDate', header: 'Registered',showDateTimeFilter:true},
    { columnDef: 'primaryContactEmail', header: 'Email' },
    { columnDef: 'primaryContactNumber', header: 'Mobile' },
    { columnDef: '', header: 'Can Login' ,columnCheckbox:ColumnType.checkbox,transparentBtn:true,
      checkboxClassField: (dataValue: any) => { return dataValue['canLogin'] ? 'fa fa-check-square-o' : 'fa fa-square-o' }
    },
    { columnDef: 'handoverDate', header: 'Handover Date',showDateTimeFilter:true},
    { columnDef: 'source', header: 'Source' },
    { columnDef: 'countryImageUrl', header: 'Country', columnType: ColumnType.image,columnDefTitle: 'countryName'}
  ]

  api: api = {
    getWithDateRange: webPortal.companyhandovers
  };

  searchUIOptions: searchUIOptions = {
    searchInput: true,
    dateRange: true
  };

  resourceMessages: resourceMessages = {
    noTableDataMessage: portalConstants.noDataFound,
    tableSearchPlaceHolder: "search company, country, email, type, frequency, reseller and campaign..."
  };

  handleUpdateButtonClick(param: any) {
    this.router.navigateByUrl(this.updateURL+param.id);
  }
}
