<lib-table *ngIf="isLoading"
[tableColumns]="companyAuditsColums" 
[api]="api" 
[title]="title" 
[refreshButton]="refreshButton"
[exportButton]="exportButton"
[addNewButton]="addNewButton"
[pdfButton]="pdfButton"
[searchUIOptions]="searchUIOptions"
[resourceMessages]="resourceMessages"
[dropDownFilter]="dropDownFilter"
(smHandleDropdownClick)="smHandleDropdownClick($event)"
(smHandleInputSearch)="smHandleInputSearch($event)"
[searchInputDivClass]="'col-md-6'"
[selectInputDivClass]="'col-md-6'"
>
</lib-table>
