<lib-table 
[tableColumns]="licenseesColumns" 
[api]="api" 
[title]="title" 
[smTitle2]="title2"
[refreshButton]="refreshButton"
[exportButton]="exportButton"
[addNewButton]="addNewButton"
(smHandleDownloadTableData)="handleDownloadVouchers($event)"
(smHandleUpdateButtonClick)="handleUpdateButtonClick($event)"
(smHandleAddButtonClick)="addNewBulkLicenses()"
[pdfButton]="pdfButton"
[showCount]="false"
>
</lib-table>
