<h3 class="marginTop2">Translation Key Management</h3>
<form class="form-horizontal marginTop2" #frm="ngForm" novalidate>
    <div class="row">
        <div class="col-md-8"><lib-text-input [smLabel]="'Key'" [smLabelClass]="'col-md-1'" [smName]="'key'"
                [smType]="'text'" [(smModel)]="translation.key" [smRequired]="true" /></div>
    </div>
    <div class="row">
        <div class="col-md-8"><lib-textarea-input [smLabel]="'Value'" [smLabelClass]="'col-md-1'" [smName]="'value'"
                [smType]="'text'" [(smModel)]="translation.value" [smRows]="8" [smRequired]="true" /></div>
    </div>
    <div class="row">
        <div class="col-md-8"><lib-select-input [smOptions]="optionsLocale" [smOptionDisplayField]="'value'"
                [smOptionValueField]="'id'" [smLabel]="'LocaleId'" [smLabelClass]="'col-md-1'" [smName]="'localeid'"
                [(smModel)]="translation.localeId" /></div>
    </div>
    <div class="row">
        <div class="col-md-8"><lib-select-input [smOptions]="optionsApp" [smOptionDisplayField]="'value'"
                [smOptionValueField]="'id'" [smLabel]="'App'" [smLabelClass]="'col-md-1'" [smName]="'app'"
                [(smModel)]="translation.app" [smRequired]="true" /></div>
    </div>
    <div class="row">
        <div class="col-md-8"><lib-select-input [smOptions]="optionsCountry" [smOptionDisplayField]="'value'"
                [smOptionValueField]="'id'" [smLabel]="'Country'" [smLabelClass]="'col-md-1'" [smName]="'country'"
                [(smModel)]="translation.country" /></div>
    </div>
    <div class="row">
        <div class="button-bar button-bar-modal">
            <lib-button [smLabel]="'Cancel'" [smType]="'button'" (smClick)="cancel()" [smIcon]="'fa fa-minus'"
                [smClass]="'btn btn-primary'" />
            <lib-button [smLabel]="'Save'" [smType]="'submit'" (smClick)="save(frm, translation)"
                [smIcon]="'fa fa-save'" [smClass]="'btn btn-secondary'" />
            <lib-button [smLabel]="'Save & Add New'" [smType]="'submit'" (smClick)="saveAndAddNew(frm, translation)"
                [smIcon]="'fa fa-save'" [smClass]="'btn btn-secondary'" />
        </div>
    </div>
</form>