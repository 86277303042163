import { Component } from "@angular/core";
import { NotificationBarService } from "packages/shared-lib/src/lib/services/notification-bar.service";
import { DataService } from "packages/shared-lib/src/lib/services/data.service";
import { webPortal } from "packages/shared-lib/src/lib/services/api/webportal.api";
import { MessagingService } from "packages/shared-lib/src/lib/services/messaging.service";
import { portalConstants } from "packages/shared-lib/src/lib/constants/portal.constants";
import { NavigationService } from "packages/shared-lib/src/lib/services/navigation.service";
import { ActivatedRoute } from "@angular/router";


@Component({
  selector: "admin-translation",
  templateUrl: "./translation.component.html",
})
export class TranslationComponent {

  id = 0;

  constructor(
    private notificationBarService: NotificationBarService,
    private dataService: DataService,
    private activatedRoute: ActivatedRoute,
    private messagingService: MessagingService,
    private navigationService: NavigationService

  ) {
    this.activatedRoute.params.subscribe((params) => {
      this.id = parseInt(params["id"]);
    });
  }
  translation: any = {};

  optionsLocale = portalConstants.locales;
  optionsApp = portalConstants.appOptions;
  optionsCountry = portalConstants.countryOptions;

  save(frm: any, translation: any) {
    this.saved(frm, translation);
  }

  saveAndAddNew(frm: any, translation: any) {
    this.saved(frm, translation, this.setForAddNew());
  }

  cancel() {
    this.getData();
  }

  setForAddNew() {
    this.id = 0;
    this.getData();
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  saved(frm: any, translation: any, onSaveSuccess?:any) {
    this.messagingService.broadcastCheckFormValidatity();

    if (!frm.invalid) {
      this.dataService.post(webPortal.saveTranslation, translation).subscribe(() => {
        this.notificationBarService.success('successfully saved translation');
        this.navigationService.goToParentState();
      });
    }
  }
  

  getData() {
    if (this.id == 0) {
      this.translation = {
        key: '',
        value: '',
        localeId: '',
        app: 'app.smeasy.co.za',
        country: ''
      }
    } else {
      this.dataService.getRecord(webPortal.getTranslation + this.id).subscribe((result: any) => {
        this.translation = result;
      });
    }
  }

  ngOnInit(){
    this.getData();
  }
}

