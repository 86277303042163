<div class="row">
    <lib-title-bar [title]="'Translations'" [reloadTranslationCache]="true"
        (handleRefreshButtonClick)="refreshHandler()" (smHandleTitleButtonAction)="reloadTranslationsCache()"
        (handleAddButtonClick)="addNewHandler()" [count]="recordCount">
    </lib-title-bar>
</div>
<form>
    <lib-form-panel>
        <div class="row">
            <div class="col-md-6"><lib-text-input [smLabelClass]="'col-md-3'" [smName]="'key'" [smType]="'text'"
                    [(smModel)]="key" [smLabel]="'Key'" /></div>
            <div class="col-md-6"><lib-text-input [smLabelClass]="'col-md-3'" [smName]="'value'" [smType]="'text'"
                    [(smModel)]="value" [smLabel]="'Value'" /></div>
        </div>
        <div class="row">
            <div class="col-md-4"><lib-text-input [smLabelClass]="'col-md-3'" [smName]="'localeId'" [smType]="'text'"
                    [(smModel)]="localeId" [smLabel]="'Locale'" /></div>
            <div class="col-md-4"><lib-text-input [smLabelClass]="'col-md-3'" [smName]="'app'" [smType]="'text'"
                    [(smModel)]="app" [smLabel]="'App'" /></div>
            <div class="col-md-4"><lib-text-input [smLabelClass]="'col-md-3'" [smName]="'country'" [smType]="'text'"
                    [(smModel)]="country" [smLabel]="'Country'" /></div>
        </div>
    </lib-form-panel>
</form>

<lib-table [tableColumns]="translationsColumns" [showTitleBar]="true" [refreshButton]="false" [addNewButton]="false"
    [showTitleBar]="false" [api]="api" (smHandleUpdateButtonClick)="handleUpdateButtonClick($event)">
</lib-table>