<lib-title-bar [title]="title1" [refreshButton]="true" [addNewButton]="false"
    (handleRefreshButtonClick)="refreshData()"></lib-title-bar>
<p>{{subTitle}}</p>
<form #kpiForm="ngForm">
    <lib-form-panel>
        <div class="row">
            <div class="col-md-4">
                <lib-select-input [smOptions]="yearOptions" [smOptionValueField]="'key'"
                    [smOptionDisplayField]="'value'" [smLabel]="'Year'" [smLabelClass]="'col-md-4'" [smName]="'year'"
                    (smModelChange)="yearChange($event)" [(smModel)]="year" />
            </div>
            <div class="col-md-4">
                <lib-select-input [smOptions]="monthOptions" [smOptionValueField]="'key'"
                    [smOptionDisplayField]="'value'" [smLabel]="'Month'" [smLabelClass]="'col-md-4'" [smName]="'month'"
                    (smModelChange)="monthChange($event)" [(smModel)]="month" />
            </div>
        </div>
    </lib-form-panel>
    <lib-title [title]="'Summary'"></lib-title>
    <table class="table table-hover table-condensed table-bordered">
        <tbody>
            <tr *ngFor="let o of kpiData.data">
                <td>{{o.country}}</td>
                <td title="{{o.description}}">
                    <a *ngIf="!noDecimalLink"
                        (click)="getDetailData(year, month, o.countryId, o.country)">{{o.value|number:'1.1-1'}}%</a>
                    <a *ngIf="noDecimalLink"
                        (click)="getDetailData(year, month, o.countryId, o.country)">{{o.value}}</a>
                </td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <th>Total</th>
                <th *ngIf="!noDecimalLink" title="{{kpiData.totalDescription}}">{{kpiData.total|number:'1.1-1'}}%</th>
                <th *ngIf="noDecimalLink" title="{{kpiData.totalDescription}}">{{kpiData.total}}</th>
            </tr>
        </tfoot>
    </table>
</form>

<div *ngIf="isDataLoaded">
    <lib-table [tableColumns]="kpi9Columns" [api]="api" [title]="tableTitle" [refreshButton]="false"
        [exportButton]="true" [addNewButton]="false" [pdfButton]="false" [searchUIOptions]="searchUIOptions"
        [additionalGetPageResponse]="'data'" [resourceMessages]="resourceMessages" [searchInputDivClass]="'col-md-6'"
        (smHandleUpdateButtonClick)="handleUpdateButtonClick($event)" [libTitleDivClass]="'col-md-8'"
        [libTitleButtonBarDivClass]="'col-md-4'">
    </lib-table>
</div>