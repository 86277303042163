import { Component } from "@angular/core";
import { webPortal } from "packages/shared-lib/src/lib/services/api/webportal.api";

@Component({
  selector: "admin-kpi10",
  templateUrl: "./kpi10.component.html",
})
export class Kpi10Component {
  title = 'KPI 10 - Free Trial signups  '
  title2 = 'KPI 10 Companies in'
  kpigetdata = webPortal.kpi10Data;
  kpigetdetail = webPortal.kpi10Detail;
  exportTable = webPortal.exportKpi10;
}
