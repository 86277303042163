import { Component } from "@angular/core";
import { webPortal } from "packages/shared-lib/src/lib/services/api/webportal.api";

@Component({
  selector: "admin-kpi11",
  templateUrl: "./kpi11.component.html",
})
export class Kpi11Component {
  title = 'KPI 11 - Conversions (from previous month)'
  title2 = 'KPI 11 Companies in'
  kpigetdata = webPortal.kpi11Data;
  kpigetdetail = webPortal.kpi11Detail;
  exportTable = webPortal.exportKpi11;
}
