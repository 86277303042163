<lib-table
[tableColumns]="lastLoggedInReportColumns" 
[api]="api" 
[title]="title" 
[refreshButton]="true"
[addNewButton]="false"
[searchUIOptions]="searchUIOptions"
[resourceMessages]="resourceMessages"
[emailButton]="true"
(smHandleEmailButtonClick)="emailLastLoggedInReport()"


/>
    
