import { Component, Input, ViewChild } from "@angular/core";
import { DataService } from "packages/shared-lib/src/lib/services/data.service";
import { TableComponent } from "packages/shared-lib/src/lib/ui/elements/table/table.component";
import { Column, ColumnType, api, resourceMessages, searchUIOptions } from "packages/shared-lib/src/lib/interfaces/webclient.interface";
import { Router } from '@angular/router';
@Component({
  selector: "admin-kpi-common",
  templateUrl: "./kpi-common.component.html",
  styleUrls: ["./kpi-common.component.scss"],
})
export class KpiCommonComponent {

  @ViewChild(TableComponent) child: TableComponent | undefined;
  @Input() title1 = '';
  @Input() subTitle?= '';
  @Input() kpiGetDetail: any;
  @Input() kpiGetData: any;
  @Input() title2 = '';
  @Input() exportTable: any;
  @Input() noDecimalLink: any;

  constructor(
    private dataService: DataService,
    private router: Router
  ) { }

  countryId = 0;
  detailCountry: any;
  filteredCount: any;
  fullCount: any;
  filterFn: any = "";
  totalCount = 0;
  filteredDataCount = 0;
  recordCount = '';
  kpiData: any;
  updateURL = '/companies/company-'
  tableTitle = ''
  isDataLoaded = false;

  year: any = new Date().getFullYear();
  month: any = new Date().getMonth();

  yearOptions: any = [
    { key: 2019, value: "2019" },
    { key: 2020, value: "2020" },
    { key: 2021, value: "2021" },
    { key: 2022, value: "2022" },
    { key: 2023, value: "2023" }]

  monthOptions: any = [
    { key: 1, value: "Jan" },
    { key: 2, value: "Feb" },
    { key: 3, value: "Mar" },
    { key: 4, value: "Apr" },
    { key: 5, value: "May" },
    { key: 6, value: "Jun" },
    { key: 7, value: "Jul" },
    { key: 8, value: "Aug" },
    { key: 9, value: "Sep" },
    { key: 10, value: "Oct" },
    { key: 11, value: "Nov" },
    { key: 12, value: "Dec" },
  ]

  monthChange(event: any) {
    this.tableTitle = this.title2 + ' ' + this.detailCountry + ' for ' + event + '/' + this.year;
  }

  yearChange(event: any) {
    this.tableTitle = this.title2 + ' ' + this.detailCountry + ' for ' + this.month + '/' + event;
  }

  ngOnInit() {
    this.getData(true)
  }

  kpi9Columns: Column[] = [
    { columnDef: 'sanitizedName', header: 'Company Login', columnType: ColumnType.link },
    { columnDef: 'primaryUserName', header: 'Username' },
    { columnDef: 'registeredDate', header: 'Registered', showDateTimeFilter: true },
    { columnDef: 'primaryContactEmail', header: 'Email' },
    { columnDef: 'primaryContactNumber', header: 'Mobile' },
    {
      columnDef: '', header: 'Can Login', columnCheckbox: ColumnType.checkbox, transparentBtn: true,  optionalCheckboxCondition: () => { return true },
      checkboxClassField: (dataValue: any) => { return dataValue['canLogin'] ? 'fa fa-check-square-o' : 'fa fa-square-o' }
    }
  ]

  api: api = {
    get: '',
    export: '',
    exportParams: { year: '', month: '', countryId: '' },
  }

  searchUIOptions: searchUIOptions = {
    searchInput: true
  }

  resourceMessages: resourceMessages = {
    tableSearchPlaceHolder: "search company login, Username or Email ..."
  }

  handleUpdateButtonClick(param: any) {
    this.router.navigateByUrl(this.updateURL + param.id);
  }

  getData(refresh: boolean) {
    if (this.year && this.month) {
      this.dataService.getLookupDataWithParams(this.kpiGetData, { year: this.year, month: this.month }, refresh).subscribe((result: any) => {
        this.kpiData = result;
      })
    }
  }

  refreshData() {
    this.getData(true)
    this.api.get = this.kpiGetDetail + '?countryId=' + this.countryId + '?month=' + this.month + '?year=' + this.year;
    this.child?.getData(true);
  }

  getDetailData(year: string, month: string, countryId: number, country: string) {
    this.countryId = countryId;
    this.api.exportParams.countryId = countryId
    this.api.exportParams.month = month
    this.api.exportParams.year = year
    this.api.export = this.exportTable
    this.api.get = this.kpiGetDetail + '?countryId=' + this.countryId + '&month=' + this.month + '&year=' + this.year;
    this.isDataLoaded = true;
    this.detailCountry = country;
    this.tableTitle = this.title2 + ' ' + this.detailCountry + ' for ' + this.month + '/' + this.year;
    this.child?.getData(true);
  }

}