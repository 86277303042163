<lib-title [title]="'ABSA Batch Activations Upload'"></lib-title>
<div class="card mt-2">
  <div class="card-body">
    <div class="row">
      <div class="col-md-6">
        <lib-file-input
          [accept]="'.csv'"
          (handleFileSelected)="onFileSelected($event)"
          [progress]="progress"
          [showProgressbar]="true"
          [type]="'file'"
          [uploaderFileName]="uploaderFileName"
        ></lib-file-input>
      </div>
    </div>
    <div class="accordion" id="accordionExample" *ngIf="helpPanel.helpRowItems.length > 0">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingOne">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseOne"
            aria-controls="collapseOne"
          >
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>&nbsp;
            <span
              class="button-margin heading"
            >{{helpPanel.helpPanelHeading}}</span>
          </button>
        </h2>
        <div
          id="collapseOne"
          class="accordion-collapse collapse"
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body">
            <ul class="list-group">
              <li
                class="list-group-item"
                *ngFor="let dataValue of helpPanel.helpRowItems"
              >
                <div class="row">
                  <div class="col-md-12">
                    <span translate="{{ dataValue }}"></span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<lib-table
  [tableColumns]="absaBatchColumns"
  [api]="api"
  [title]="title"
  [exportButton]="false"
  [searchUIOptions]="searchUIOptions"
  [addNewButton]="false"
  [resourceMessages]="resourceMessages"
  (smHandleTableModal)="handleTableModal($event)"
  [searchInputDivClass]="'col-md-4'"
  [dateRangeDivClass]="'col-md-4'"
  [todayAndYesterdayDate]="true"
></lib-table>