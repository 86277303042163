import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { Router } from "@angular/router";
import { Column, ColumnType, api } from "packages/shared-lib/src/lib/interfaces/webclient.interface";
import { webPortal } from "packages/shared-lib/src/lib/services/api/webportal.api";
import { DataService } from "packages/shared-lib/src/lib/services/data.service";
import { ModalService } from "packages/shared-lib/src/lib/services/modal.service";
import { ReportsService } from "packages/shared-lib/src/lib/services/reports.service";
import { TableComponent } from "packages/shared-lib/src/lib/ui/elements/table/table.component";


@Component({
  selector: "admin-bulklicensedetails",
  templateUrl: "./bulklicensedetails.component.html",
})
export class BulklicensedetailsComponent implements OnInit {
  @ViewChild(TableComponent) child: TableComponent | undefined;

  constructor(private router: Router, private dataService: DataService, private activatedRoute: ActivatedRoute, private modalService: ModalService, private reportsService: ReportsService) {
    this.activatedRoute.params.subscribe(params => {
      this.licenseeId = parseInt(params['id']);
      this.invoiceNumber = params['invoiceNumber']
      this.api.get = webPortal.getBulkLicenses + this.licenseeId;
    });
  }
  exportButton = false;
  refreshButton = true;
  pdfButton = false;
  addNewButton = true;
  title = 'Bulk Licenses';
  title2 = '';
  updateURL = '/licensees/';
  licenseeId = 0;
  invoiceNumber: any;
  params: any;
  licenseeName: any;

  ngOnInit() {
    this.dataService.getRecordWithParams(webPortal.getLicenseID + this.licenseeId, null).subscribe((res: any) => {
      this.title2 = 'Licensee - ' + res.name;
      this.licenseeName = res.name;
    })
  }

  licenseesColumns: Column[] = [
    { columnDef: 'invoiceNumber', header: 'Invoice Number', columnType: ColumnType.link, },
    { columnDef: 'numberOfVouchers', header: 'Number of Licenses' },
    { columnDef: 'numberOfRedeemedVouchers', header: 'Redeemed Licenses' },
    { columnDef: 'numberOfExpiredVouchers', header: 'Expired Licences' },
    { columnDef: 'daysValidFor', header: 'Days Valid' },
    { columnDef: 'issuedDate', header: 'Date Issued', showDateTimeFilter: true },
    { columnDef: 'redemptionStartDate', header: 'Redemption From', showDateFilter: true },
    { columnDef: 'redemptionDaysValidFor', header: 'Redemption Days Valid For' },
    { columnDef: '', header: 'Download', columnDownload: ColumnType.download },
  ];

  api: api = {
    get: webPortal.getBulkLicenses
  };

  addNewBulkLicenses() {
    this.modalService.showAddNewBulkBulkLicensesModal(this.licenseeName, this.licenseeId).result.then(() => {
      this.child?.getData(true);
    });
  }

  handleDownloadVouchers(param: any) {
    const paramFilters: any = {}
    paramFilters.licenseeId = this.licenseeId,
      paramFilters.invoiceNumber = param.invoiceNumber
    this.reportsService.getReportWithParams(webPortal.downloadVouchers, paramFilters).then((url: any) => {
      window.open(url, '_blank', '');
    });
  }

  handleUpdateButtonClick(param: any) {
    this.router.navigateByUrl(this.updateURL + this.licenseeId + '/' + param.data.invoiceNumber);
  }
}
