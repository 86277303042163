
 <lib-table 
 [tableColumns]="newRegistrationColumns" 
 [api]="api" 
 [title]="title" 
 [refreshButton]="true"
 [exportButton]="false"
 [addNewButton]="false"
 [pdfButton]="false"
 [refreshButton]="true"
 [searchUIOptions]="searchUIOptions"
 [resourceMessages]="resourceMessages"
 (smHandleUpdateButtonClick)="handleUpdateButtonClick($event)"
 [dateRangeDivClass]="'col-md-4'"
 [searchInputDivClass]="'col-md-4'"
 [todayAndYesterdayDate]="true"
 >
 </lib-table>
 