import { Component, ViewChild } from "@angular/core";
import {
  Column,
  ColumnType,
  api,
} from "packages/shared-lib/src/lib/interfaces/webclient.interface";
import * as moment from "moment";
import { Router } from '@angular/router';
import { webPortal } from "packages/shared-lib/src/lib/services/api/webportal.api";
import { DataService } from "packages/shared-lib/src/lib/services/data.service";
import { NotificationBarService } from "packages/shared-lib/src/lib/services/notification-bar.service";
import { TableComponent } from "packages/shared-lib/src/lib/ui/elements/table/table.component";


@Component({
  selector: "admin-translations",
  templateUrl: "./translations.component.html",
})
export class TranslationsComponent {
  @ViewChild(TableComponent) child: TableComponent | undefined;

  constructor(
    private dataService: DataService,
    private router: Router,
    private notificationBarService: NotificationBarService,
  ) { }

  key:any = '';
  value:any = '';
  localeId:any = '';
  app:any = '';
  country:any = '';
  addURL = 'translations/0';
  updateURL = 'translations/';
  start = moment();

  translationsColumns: Column[] = [
    { columnDef: "key", header: "Key", columnType: ColumnType.link },
    { columnDef: "value", header: "Value" },
    { columnDef: "localeId", header: "LocaleId" },
    { columnDef: "app", header: "App" },
    { columnDef: "countryImageUrl", header: "Country",columnType: ColumnType.image, columnDefTitle: 'countryName'},
    { columnDef: "updated", header: "Updated", showDateTimeFilter: true },
  ]

  api: api = {
    get: webPortal.getTranslationsData,
    dropDownFilter: {
      key:  this.key,
      value: this.value,
      localeid: this.localeId,
      app: this.app,
      t: this.start.millisecond(),
      country:this.country,
    }
  }
  recordCount: any = '';

  ngAfterContentChecked() {
    this.recordCount = this.child?.recordCount;
  }

  refreshHandler(){
    this.api.dropDownFilter.key = this.key;
    this.api.dropDownFilter.value = this.value;
    this.api.dropDownFilter.localeId = this.localeId;
    this.api.dropDownFilter.app = this.app;
    this.api.dropDownFilter.t = this.start.millisecond();
    this.api.dropDownFilter.country = this.country;
    this.child?.getData(true);
     
  }

  reloadTranslationsCache() {
    this.dataService.post(webPortal.reloadTranslationsCache).subscribe((result: any) => {
      this.notificationBarService.success(result);
    });
  }
  addNewHandler() {
    this.router.navigateByUrl(this.addURL);
  }
  handleUpdateButtonClick(param: any) {
    this.router.navigateByUrl(this.updateURL + param.id);
  }
  
}
