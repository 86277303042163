import { Component } from "@angular/core";
import { webPortal } from "packages/shared-lib/src/lib/services/api/webportal.api";

@Component({
  selector: "admin-kpi9",
  templateUrl: "./kpi9.component.html",
})
export class Kpi9Component {
  title = 'KPI 9 - Churn (deactivations)'
  subtitle = 'This data is only accurate from Nov 2016 forwards'
  title2 = 'KPI 9 Deactivated Companies in'
  kpigetdata = webPortal.kpi9Data;
  kpigetdetail = webPortal.kpi9Detail;
  exportTable = webPortal.exportKpi9;
}
