<lib-table 
[tableColumns]="freeTrialRegistrationsColums" 
[api]="api" 
[title]="title" 
[refreshButton]="refreshButton"
[exportButton]="exportButton"
[addNewButton]="addNewButton"
[pdfButton]="pdfButton"
[searchUIOptions]="searchUIOptions"
[resourceMessages]="resourceMessages"
(smHandleUpdateButtonClick)="handleUpdateButtonClick($event)"
[dropDownFilter]="dropDownFilter"
[selectInputDivClass]="'col-md-6'"
[searchInputDivClass]="'col-md-6'"
>
</lib-table>