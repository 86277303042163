import { Component } from "@angular/core";
import { Column,api,searchUIOptions,resourceMessages,dropDownFilter,ColumnType} from "packages/shared-lib/src/lib/interfaces/webclient.interface";
import { webPortal } from "packages/shared-lib/src/lib/services/api/webportal.api";
import { portalConstants } from "packages/shared-lib/src/lib/constants/portal.constants";
import { Router } from '@angular/router';
import { ReportsService } from "packages/shared-lib/src/lib/services/reports.service";

@Component({
  selector: "admin-subscriptions-ending-soon",
  templateUrl: "./subscriptions-ending-soon.component.html"
})
export class SubscriptionsEndingSoonComponent {


  constructor(
    private router : Router,
    private reportsService: ReportsService){}

  title ='Subscriptions Ending Soon';
  defaultDaysSelected = 7;
  updateURL = '/companies/company-';
  selectedDay : any;

  subscriptionColumns :Column[] = [
    {columnDef: 'daysToEnd', header: 'Days To End',columnType :ColumnType.pendingPaymentSubscription,colWidth:'15%',
    ngClassCondition: (dataValue: any) => { return dataValue['daysToEnd'] <  7 ?
     'label label-pill label-danger': dataValue['daysToEnd'] >= 7 && dataValue['daysToEnd'] < 15  ? 
     'label label-pill label-warning-pending':dataValue['daysToEnd'] >= 15  ? 
     'label label-pill label-success':'label label-pill label-danger'}},
    {columnDef: 'sanitizedName', header: 'Company Name' ,columnType: ColumnType.link},
    {columnDef: 'registeredDate', header: 'Registered Date',showDateTimeFilter:true,colWidth:'20%' },
    {columnDef: 'expiry', header: 'Expiry' ,showDateTimeFilter:true,colWidth:'20%' },
    {columnDef: 'paymentType', header: 'Payment Type' },
    {columnDef: 'subscriptionType', header: 'Subscription Type',colWidth:'20%' },
    {columnDef: 'subscriptionStatus', header: 'Subscription Status',colWidth:'20%' },
    { columnDef: '', header: 'is Active' ,columnCheckbox:ColumnType.checkbox,transparentBtn:true,colWidth:'15%',
    checkboxClassField: (dataValue: any) => { return dataValue['isActive'] ? 'fa fa-check-square-o' : 'fa fa-square-o' }
    },
    {columnDef: 'source', header: 'Source' },


  ]
  api:api={
    get:webPortal.getSubscriptionsEndingSoon,
    dropDownFilter: {selectedDay: '7'},
  }

  searchUIOptions: searchUIOptions = {
    dropdown: true,
    searchInput :true
    };

  resourceMessages: resourceMessages = {
    noTableDataMessage: portalConstants.noDataFound,
    tableSearchPlaceHolder: 'search company or country ...'

  };

  dropDownFilter: dropDownFilter = {
    smOptions: portalConstants.ExpiringSubscriptionDays,
    smRequired: false,
    smLabel: "Days",
    smOptionDisplayField: 'value',
    smOptionValueField: 'id',
    smPlaceholder: "select",
    smLabelClass: "col-md-2",
    selectedSearchFilterDropdown: this.defaultDaysSelected,
  };

  downloadSubscriptionsEndingSoon () {
    const paramFilters:any = {};
    paramFilters.selectedDay = this.selectedDay;
    return this.reportsService.getReportWithParams(webPortal.downloadSubscriptionsEndingSoonRoute, paramFilters).then((url: any) => {
      window.open(url, '_blank', '');
    });
  }

  handleUpdateButtonClick(param: any) {
    this.router.navigateByUrl(this.updateURL+param.id);
  }

  daySelect(event:any){
    this.selectedDay = event
  }
}
