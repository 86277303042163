<lib-table 
[tableColumns]="payingvoucherColumns" 
[api]="api" 
[title]="title" 
[refreshButton]="true"
[showTitleBar]="true"
[exportButton]="exportButton"
[addNewButton]="addNewButton"
[pdfButton]="false"
[searchUIOptions]="searchUIOptions"
(smHandleUpdateButtonClick)="handleUpdateButtonClick($event)"
[dateFilter]="dateFilter"
[showCount]="false"
[singleDateFilterDivClass]="'col-md-6'"
>
</lib-table>